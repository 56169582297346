import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter, Reference } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { OverviewDto, QueryRequestDto, QueryResultDto } from '@nuis/common';
import { firstValueFrom } from 'rxjs';
import { mapNotificationDto, NotificationDto, NotificationFilterDto, NotificationQueryResultDto } from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class NotificationApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);

    public async getNotifications(
        options: QueryRequestDto<NotificationFilterDto>,
    ): Promise<QueryResultDto<NotificationDto>> {
        const response = await firstValueFrom(
            this.http.post<NotificationQueryResultDto<NotificationDto & { timestamp: string; emailId: string | null }>>(
                `${this.config.baseUrl}Notifications/QueryList`,
                options,
            ),
        );

        return {
            totalRecords: response.totalRecords,
            records: response.notifications.map(mapNotificationDto),
        };
    }

    public async getNotificationsByReference(reference: Reference) {
        const response = await firstValueFrom(
            this.http.get<(NotificationDto & { timestamp: string; emailId: string | null })[]>(
                `${this.config.baseUrl}Notifications/GetNotifications`,
                { params: { ...reference } },
            ),
        );

        return response.map(mapNotificationDto);
    }

    public async getMessagesTypes(): Promise<OverviewDto[]> {
        const messageTypes: OverviewDto[] = [
            { key: 'email', name: this.translate.instant('messageTypes.email'), color: null },
            { key: 'sms', name: this.translate.instant('messageTypes.sms'), color: null },
        ];

        return messageTypes;
    }

    public async getNotificationTypes(): Promise<OverviewDto[]> {
        const notificationTypes: OverviewDto[] = [
            { key: 'welcome', name: this.translate.instant('notificationTypes.welcome'), color: null },
            { key: 'start', name: this.translate.instant('notificationTypes.start'), color: null },
            { key: 'end', name: this.translate.instant('notificationTypes.end'), color: null },
            { key: 'startsuspended', name: this.translate.instant('notificationTypes.startsuspended'), color: null },
            { key: 'proposalLink', name: this.translate.instant('notificationTypes.proposalLink'), color: null },
            {
                key: 'deactivationSuccess',
                name: this.translate.instant('notificationTypes.deactivationSuccess'),
                color: null,
            },
            {
                key: 'startTravelWarning',
                name: this.translate.instant('notificationTypes.startTravelWarning'),
                color: null,
            },
            {
                key: 'cancellationbycustomer',
                name: this.translate.instant('notificationTypes.cancellationbycustomer'),
                color: null,
            },
            {
                key: 'cancellationbyinsurer',
                name: this.translate.instant('notificationTypes.cancellationbyinsurer'),
                color: null,
            },
            {
                key: 'futureterminationdate',
                name: this.translate.instant('notificationTypes.futureterminationdate'),
                color: null,
            },
            {
                key: 'cancellationDueToOldAge',
                name: this.translate.instant('notificationTypes.cancellationDueToOldAge'),
                color: null,
            },
            {
                key: 'additionalInsuredPersonPreRemoval',
                name: this.translate.instant('notificationTypes.additionalInsuredPersonPreRemoval'),
                color: null,
            },
            {
                key: 'additionalInsuredPersonRemoval',
                name: this.translate.instant('notificationTypes.additionalInsuredPersonRemoval'),
                color: null,
            },
            {
                key: 'coverChangeSuccess',
                name: this.translate.instant('notificationTypes.coverChangeSuccess'),
                color: null,
            },
            {
                key: 'newCountryTravelWarning',
                name: this.translate.instant('notificationTypes.newCountryTravelWarning'),
                color: null,
            },
            { key: 'updatemsisdn', name: this.translate.instant('notificationTypes.updatemsisdn'), color: null },
            {
                key: 'policyendorsementgeneral',
                name: this.translate.instant('notificationTypes.policyendorsementgeneral'),
                color: null,
            },
            {
                key: 'partialTravelWarning',
                name: this.translate.instant('notificationTypes.partialTravelWarning'),
                color: null,
            },
            {
                key: 'exceededtraveltermination',
                name: this.translate.instant('notificationTypes.exceededtraveltermination'),
                color: null,
            },
            {
                key: 'contractSuspended',
                name: this.translate.instant('notificationTypes.contractSuspended'),
                color: null,
            },
            {
                key: 'deactivationFailedExpired',
                name: this.translate.instant('notificationTypes.deactivationFailedExpired'),
                color: null,
            },
            {
                key: 'contractSuspendedNoTravel',
                name: this.translate.instant('notificationTypes.contractSuspendedNoTravel'),
                color: null,
            },
            {
                key: 'proposalLegalInformation',
                name: this.translate.instant('notificationTypes.proposalLegalInformation'),
                color: null,
            },
        ];

        return notificationTypes;
    }

    public async getNotificationStatuses(): Promise<OverviewDto[]> {
        const notificationStatuses: OverviewDto[] = [
            { key: 'Sent', name: this.translate.instant('notification.status.sent'), color: 'gray' },
            { key: 'SentByPartner', name: this.translate.instant('notification.status.sentByPartner'), color: 'gray' },
            { key: 'Open', name: this.translate.instant('notification.status.open'), color: 'orange' },
            { key: 'Failed', name: this.translate.instant('notification.status.failed'), color: 'red' },
        ];

        return notificationStatuses;
    }
}
